import axios from "axios";
import React, { useState, useEffect, useRef, useCallback } from "react";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { addcustinfo, getCustaddress, loginApi } from "../utils/api";
import { setPincode } from '../store/feature/pincodeSlice';
import { useSelector } from 'react-redux';
import DeliveryB from "../assets/deliveryboy.png";
import { Modal } from 'bootstrap';
import {
  CButton,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  COffcanvasBody,
  CCloseButton,
} from "@coreui/react";
import{ setCookie, getCookie, deleteCookie } from '../components/Cookie'
import { useDispatch } from "react-redux";
import { updateKeyValue,setActiveTab } from "../store/feature/userSlice";
import { toast } from "react-toastify";
const MAP_KEY = "AIzaSyA90kZbFA-_GeeQ67T7kTb7VQRSt_LoOXc";


const Navbar = ({ isStatic,text }) => {
  const dispatch = useDispatch();
  // const [activeTab, setActiveTab] = useState(() => {
  //   const savedTab = localStorage.getItem("activeTab");
  //   return savedTab ? savedTab : "Collection";
  // });
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const storedAddress = JSON.parse(localStorage.getItem("userAddress"));

  // Handle tab click
  const activeTab = useSelector((state) => state.User.activeTab);

  const handleTabClick = (tab) => {
    console.log(tab,"tablink")

    dispatch(setActiveTab(tab));

  };

  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // console.log(show,"")

  // #location
  const [suggestions, setSuggestions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [locationPop, setLocationPop] = useState(false);
  const inputRef = useRef(null);
  const [location, setLocation] = useState(null);
  const [selectSuggestion, setSelectSuggestion] = useState(null);

  const fetchSuggestions = async (query) => {
    if (query.length > 0) {
      try {
        const response = await axios.get(
          `https://www.highwycombebites.com/api/customer/geolocation?query=${query}&type=regions`
        );

        const data = response.data;
        if (data.predictions) {
          const formattedSuggestions = data.predictions.map((prediction) => ({
            place_id: prediction.placeId,
            description: `${prediction.structuredFormatting.mainText}`,
            secondaryText: `${prediction.structuredFormatting.secondaryText} , ${prediction.structuredFormatting.mainText}`,
          }));
          setSuggestions(formattedSuggestions);
          setLocationPop(true);
        }
      } catch (error) {
        console.error("Error fetching location suggestions:", error);
      }
    } else {
      setSuggestions([]);
      setLocationPop(false);
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 300),
    []
  );

  const handleInputChange = (event) => {
    const query = event.target.value;
    console.log(query, "input");
    debouncedFetchSuggestions(query);
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion(suggestion.secondaryText);
    inputRef.current.value = suggestion.secondaryText;
    setSuggestions([]);
    setLocationPop(false);

    // Close the location modal
    const locationModalElement = document.getElementById("location");
    if (locationModalElement) {
      // Remove 'show' class to hide the modal and set 'display' to 'none'
      locationModalElement.classList.remove("show");
      locationModalElement.style.display = "none";
      document.body.classList.remove("modal-open");

      // Remove the backdrop
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.classList.remove("show");
        backdrop.remove();
      }
    } else {
      console.error("Location modal not found.");
    }

    // Open the address-details modal
    const addressDetailsModalElement =
      document.getElementById("address-details");
    if (addressDetailsModalElement) {
      // Add 'show' class to display the modal and set 'display' to 'block'
      addressDetailsModalElement.classList.add("show");
      addressDetailsModalElement.style.display = "block";
      document.body.classList.add("modal-open");

      // Add backdrop
      // const backdrop = document.createElement('div');
      // backdrop.className = 'modal-backdrop fade show';
      // document.body.appendChild(backdrop);
    } else {
      console.error("Address details modal not found.");
    }
  };

  const handlebtnclose = () => {
    const addressDetailsModalElement =
      document.getElementById("address-details");
    if (addressDetailsModalElement) {
      // Remove 'show' class to hide the modal
      addressDetailsModalElement.classList.remove("show");
      addressDetailsModalElement.style.display = "none";

      // Remove the backdrop element
      const backdrop = document.querySelector(".modal-backdrop.fade.show");
      if (backdrop) {
        backdrop.remove(); // Remove the backdrop from the DOM
      }

      // Remove 'modal-open' class from the body
      document.body.classList.remove("modal-open");
    } else {
      console.error("Address details modal not found.");
    }
  };

  const handlelocation = async (suggestion) => {
    console.log(suggestion.secondaryText, "suggestion");
    const response = await axios.get(
      `https://highwycombebites.com/api/customer/geocode?place=${suggestion.secondaryText}`
    );
    if (response?.data?.results[0]?.geometry?.location) {
      const locationData = response.data.results[0].geometry.location;
      setLocation(locationData);
      console.log(location, "lat,lng");
    }
    // Find the postcode from the addressComponents
    const addressComponents = response.data.results[0].addressComponents;
    const postalCodeComponent = addressComponents.find(component =>
      component.types.includes('postal_code')
    );

    if (postalCodeComponent) {
      const postalCode = postalCodeComponent.longName.slice(0, 4).toUpperCase();
      console.log(postalCode, "postal code");
  
      // Create an object to store
      const postalCodeData = {
          longName: postalCode.slice(0, 4).toUpperCase(),
          shortName: postalCode,
          types: ["postal_code"]
      };
  
      // Save the object in local storage as a JSON string
      localStorage.setItem('pincode', JSON.stringify(postalCodeData));
      setCookie('pincode', JSON.stringify(postalCodeData));
      
  } else {
      console.log("Postal code not found in addressComponents");
  }
  
  };


  useEffect(() => {
    if (location) {
      console.log("Location has been updated:", location);
    }
  }, [location]);

  // address

  const [address, setAddress] = useState([]);
  const Navigate = useNavigate();
  // const [addres, setAddres] = useState(''); // Added address state
  const [title, setTitle] = useState(""); 
  const pincode = useSelector((state) => state.pincode);
  const [inputPincode, setInputPincode] = useState('');
  const [remove,setRemove] = useState("")

  useEffect(() => {
    // Load pincode from local storage on component mount
    const savedPincode = localStorage.getItem("pincode") || '';
    setRemove(savedPincode)
    setInputPincode(savedPincode);
    dispatch(setPincode(savedPincode)); // Update Redux state with the saved pincode
  }, [dispatch]);

  useEffect(() => {
    // Update local storage when pincode changes
    if (inputPincode) {
      localStorage.setItem("pincode", inputPincode);
      setCookie("pincode", inputPincode);
    }
  }, [inputPincode]);

  const addressAdd = async (e) => {
    e.preventDefault();
    const storedUser = JSON.parse(localStorage.getItem("user"));

    // Check if location contains 'lat' and 'lng', or 'latitude' and 'longitude'
  const lat = location?.lat || location?.latitude || '';
  const lng = location?.lng || location?.longitude || '';

  console.log(location, "locationsss");

  const data = {
    userid: storedUser?.userid,
    title: title,
    address: selectedSuggestion,
    lat: lat.toString(), // Ensure it's a string, even if it's empty
    lng: lng.toString(), // Ensure it's a string, even if it's empty
    pincode: pincode
  };

  console.log(data, "address");
    const add = await addcustinfo(data);
    try{
    if(add.status){
      // Reload the window after 3 seconds (3000 milliseconds)
      
setTimeout(() => {
  window.location.reload();
}, 1500);
    }

  }catch (error) {
    // toast.error("not added address:");
    console.log(error)
    // throw error;
  }

    if (!storedUser) {
      localStorage.setItem("userAddress", JSON.stringify(data));
      setCookie("userAddress", JSON.stringify(data));
      dispatch(updateKeyValue({ key: "userAddress", value: data }));
    } else {
      callData();
      // setLetLng()
    }
    // Reload the window after 3 seconds (3000 milliseconds)
setTimeout(() => {
  window.location.reload();
}, 3000);
dispatch(setPincode(inputPincode));
    setTitle("");
    // setAddres('');
  };


  const callData = async (visibleCount = 3) => {
    try {
      if (storedUser) {
        const data = {
          custid: storedUser?.userid,
        };
        const res = await getCustaddress(data);
        const addresses = res?.useraddress || []; // Default to empty array if undefined
        setAddress(addresses.slice(0, visibleCount)); // Only set the number of addresses to be visible
  
        if (addresses.length > 0) {
          const lastAddress = addresses[addresses.length - 1]; // Get the last address
          localStorage.setItem("userAddress", JSON.stringify(lastAddress)); // Save the last address object to 'userAddress'
          setCookie("userAddress", JSON.stringify(lastAddress)); // Save the last address object to 'userAddress'
          dispatch(updateKeyValue({ key: "userAddress", value: lastAddress }));
        } else {
          const lat = location?.lat?.toString();
          const lng = location?.lng?.toString();
          const data = {
            title: title,
            address: selectedSuggestion,
            lat: lat,
            lng: lng,
          };
          localStorage.setItem("userAddress", JSON.stringify(data));
          setCookie("userAddress", JSON.stringify(data));
          console.log(data, "storage");
          console.log(location, "location");
        }

        console.log("data", res);
      }
    } catch (error) {
      console.error("Error fetching addresses", error);
      
    }
  };

  const [visibleCount, setVisibleCount] = useState(3); // Number of addresses to show initially
  const [allAddressesLoaded, setAllAddressesLoaded] = useState(false); // Track if all addresses are loaded
  
  const showMoreAddresses = () => {
    setVisibleCount((prevCount) => {
      const newCount = prevCount + 3; 
      if (newCount >= address.length) {
        setAllAddressesLoaded(true); 
      }
      return newCount;
    });
  };

  const showLessAddresses = () => {
    setVisibleCount(3);
    setAllAddressesLoaded(false);
  };

  useEffect(() => {
    callData(visibleCount);
  }, [visibleCount]);



    

  const getWordsWithinLimit = (text, charLimit) => {
    if (!text) return "";
  
    let words = text.split(" ");
    let result = [];
    let currentLength = 0;
  
    for (let word of words) {
      if (currentLength + word.length + result.length > charLimit) break;
      result.push(word);
      currentLength += word.length;
    }
  
    return result.join(" ");
  };

  
  const saveAddressToLocalStorage = (address) => {
    localStorage.setItem("userAddress", JSON.stringify(address));
    setCookie("userAddress", JSON.stringify(address));
    dispatch(updateKeyValue({ key: "userAddress", value: address }));  };


    const handleLocationClick = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
     setLocation(position.coords)
     console.log(location?.latitude,"handle")
          try {
            // Fetch address data from Google Maps API
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBePruYxWbLAsOVswTaH0OHPyv8vx-mMcc`
            );
    
            // Extract formatted address
            const address = response?.data?.results[0]?.formatted_address;
            console.log(latitude,longitude,address,"handlelocation")

            setSelectedSuggestion(address); // Update the suggestion in state
            inputRef.current.value = address; // Update the input field
            setSuggestions([]); // Clear suggestions
            setLocationPop(false); // Close the dropdown
    
            let postalCodeData = null;

            // Iterate through all results to find a postal_code
            for (const result of response?.data?.results || []) {
              const addressComponents = result.address_components || [];
              const postalCodeComponent = addressComponents.find((component) =>
                component.types.includes("postal_code")
              );
    
              if (postalCodeComponent) {
                postalCodeData = {
                  longName: postalCodeComponent.long_name.slice(0, 4).toUpperCase(),
                  shortName: postalCodeComponent.short_name,
                  types: postalCodeComponent.types,
                };
                break; // Exit loop once postal code is found
              }
            }
    
            // Check if postal code was found
            if (postalCodeData) {
              // Remove old pincode
              localStorage.removeItem("pincode");
              setCookie("pincode", ""); // Clear cookie if applicable
    
              // Save the new pincode in localStorage and Redux
              localStorage.setItem("pincode", JSON.stringify(postalCodeData));
              setCookie("pincode", JSON.stringify(postalCodeData));
              dispatch(setPincode(postalCodeData));
    
              console.log("New pincode saved:", postalCodeData);
            } else {
              console.warn("Postal code not found in the address components.");
            }
          } catch (error) {
            console.error("Error fetching address or saving pincode:", error);
          }
        });
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    };
    
    
    
    const [hasBoxShadow, setHasBoxShadow] = useState(true);
    const removePincode = (e)=>{
      e.preventDefault()
      setRemove("")
      localStorage.removeItem("pincode");
      setHasBoxShadow(false)

    }

// location model 


const { success } = useSelector((state) => state.User);
const [showLocationModal, setShowLocationModal] = useState(false);

useEffect(() => {
  const hasVisited = localStorage.getItem('hasVisited');

  if (!hasVisited) {
    setShowLocationModal(true);
    localStorage.setItem('hasVisited', 'true');
  }
  

  // Check if success state needs to trigger modal or other actions
  if (success) {
    // You can perform actions based on success here
  }
}, [success]);

useEffect(() => {
  if (showLocationModal) {
    // Ensure the modal exists in the DOM before initializing
    const modalElement = document.getElementById('location');
    if (modalElement) {
      const modal = new Modal(modalElement);
      modal.show();
    } else {
     
      console.error('Modal element not found.');
      
    }
  }
}, [showLocationModal]);



  return (
    <>
      <header
      style={{
        position: isStatic ? "static" : "fixed",
       
      }}
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg p-0">
            <Link to={"/"}>
              <img
                className="img-fluid logo"
                src="/assets/images/newlogo3.png"
                alt="logo"
              />
            </Link>
            <Link
              target="_blank"
              href=""
              data-bs-toggle="modal"
              data-bs-target="#location"
              className="btn btn-sm theme-btn location-btn mt-0 ms-3 d-flex align-content-center gap-1"
              style={{ background: "#dff5ff", color: "#000" }}
            >
              <i className="ri-map-pin-line" style={{ color: "#01b2fe" }} />
            {storedAddress?.title} {getWordsWithinLimit(storedAddress?.address,20)}...
            </Link>
            <CButton
              className="navbar-toggler ml-auto"
              data-bs-target="#offcanvasNavbar"
              // onClick={handleShow}
              onClick={() => setVisible(true)}
            >
              <span className="navbar-toggler-icon">
                <i className="ri-menu-line" />
              </span>
            </CButton>
            {/* {text !== "dining" || text!=="ride" && ( */}
            <div className="nav-option order-md-2">
              <div className="tab">
                <div className="d-flex text-center">
                  <Link
                    className={`tablinks ${
                      activeTab === "Delivery" ? "active" : ""
                    }`}
                    style={{ width: "auto", padding: "7px 20px" }}
                    onClick={() => handleTabClick("Delivery")}
                  >
                    <p className="f-12">
                      <img src={DeliveryB} style={{ width: "15px" }}/> Delivery
                    </p>{" "}
                    <p className="smtext">35 - 50 Min</p>
                  </Link>
                  <Link
                    className={`tablinks ${
                      activeTab === "Collection" ? "active" : ""
                    }`}
                    style={{ width: "auto", padding: "7px 20px" }}
                    onClick={() => handleTabClick("Collection")}
                  >
                    <p className="f-12">
                      <i className="fa fa-shopping-bag" aria-hidden="true" />{" "}
                      Collection
                    </p>{" "}
                    <p className="smtext">15 - 25 Min</p>
                  </Link>
                </div>
              </div>
            </div>
            {/* )} */}
            <COffcanvas
              placement="end"
              visible={visible}
              onHide={() => setVisible(false)}
            >
              <COffcanvasHeader>
                <COffcanvasTitle>Menu</COffcanvasTitle>
                <CCloseButton
                  className="text-reset"
                  onClick={() => setVisible(false)}
                />
              </COffcanvasHeader>
              <COffcanvasBody>
                <ul className="navbar-nav justify-content-center flex-grow-1">
                  <li className="nav-item">
                    <Link to={"/"} className="nav-link" style={{ fontWeight: "600px" }}>
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/dining"} className="nav-link" style={{ fontWeight: "600px" }}>
                      Dining
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/offers"} className="nav-link" style={{ fontWeight: "600px" }}>
                      Offers
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    <Link to={"/contact"} className="nav-link">
                      Contact
                      
                    </Link>
                  </li> */}
                </ul>
              </COffcanvasBody>
            </COffcanvas>
          </nav>
        </div>
      </header>
      {showLocationModal ? (
      <div
        className="modal fade location-modal"
        id="location"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">
                <h5 className="fw-semibold">Select a Location</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
            </div>
            <div className="modal-body">
            <h6 className="mb-0"><strong>Currentaddress : </strong>{storedAddress?.address}</h6>
<br></br>
            {/* <div className="d-flex align-items-center">
  <h6 className="mb-0">Pincode :- {remove}</h6>
  <button className="btn-close  ms-2"  style={{ height: '0', boxShadow: 'none' }} onClick={removePincode} ></button>
</div> */}

              <div className="search-section">
                <form className="form_search" role="form">
                  <div className="search-container">
                    <input
                      type="search"
                      placeholder="Enter your postcode"
                      className="search-input"
                      onChange={handleInputChange}
                      ref={inputRef}
                      onFocus={() => setLocationPop(true)}
                    />
                    {locationPop && suggestions.length > 0 && (
                      <ul className="suggestions-dropdown">
                        {suggestions.map((suggestion) => (
                          <li
                            className="recent-location"
                            key={suggestion.place_id}
                            onClick={() => {
                              handleSuggestionClick(suggestion);
                              handlelocation(suggestion);
                              setSelectSuggestion(suggestion)
                            }}
                          >
                            <div className="recant-address">
                              <i className="ri-map-pin-line theme-color" />
                              <div>
                                <h5>{suggestion.description}</h5>
                                <h6>{suggestion.secondaryText}</h6>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </form>
              </div>
              <Link href="" className="current-location">
                <div className="current-address">
                  <i className="ri-focus-3-line focus" />
                  <div onClick={handleLocationClick}>
                    <h5 className="mt-1">Use current location</h5>
                  </div>
                </div>
                <i className="ri-arrow-right-s-line arrow" />
              </Link>
              <h5 className="mt-sm-3 mt-2 fw-medium recent-title dark-text">
                Recent Location
              </h5>
              {address.map((address) => (
        <Link 
          href="#" 
          className="recent-location" 
          key={address.pkid} 
          onClick={() => saveAddressToLocalStorage(address)}
        >
          <div className="recant-address">
            <i className="ri-map-pin-line theme-color" />
            <div data-bs-dismiss="modal"
                aria-label="Close">
              <h5 >{address.title}</h5>
              <h6>{address.address}</h6>
            </div>
          </div>
        </Link>
      ))}
    {!allAddressesLoaded ? (
        <button onClick={showMoreAddresses} className="toggle-btn show-more-btn">
          Show More
        </button>
      ) : (
        <button onClick={showLessAddresses} className="toggle-btn show-more-btn">
          Show Less
        </button>
      )}
            </div>
            <div className="modal-footer">
              <Link href="#" className="btn gray-btn" data-bs-dismiss="modal">
                Close
              </Link>
              <Link
                className="btn theme-btn mt-0"
                data-bs-dismiss="modal"
                onClick={addressAdd}
              >
                Save
              </Link>
            </div>
          </div>
        </div>
      </div>
       ) : 
       <div
       className="modal fade location-modal"
       id="location"
       data-bs-backdrop="static"
       data-bs-keyboard="false"
       tabIndex={-1}
     >
       <div className="modal-dialog modal-dialog-centered">
         <div className="modal-content">
           <div className="modal-header">
             <div className="modal-title">
               <h5 className="fw-semibold">Select a Location</h5>
               <button
                 type="button"
                 className="btn-close"
                 data-bs-dismiss="modal"
                 aria-label="Close"
               />
             </div>
           </div>
           <div className="modal-body">
           <h6 className="mb-0"><strong>Currentaddress : </strong> {storedAddress?.title}, {storedAddress?.address}</h6>
<br></br>
           {/* <div className="d-flex align-items-center">
 <h6 className="mb-0">Pincode :- {remove}</h6>
 <button className="btn-close  ms-2"  style={{ height: '0', boxShadow: 'none' }} onClick={removePincode} ></button>
</div> */}

             <div className="search-section">
               <form className="form_search" role="form">
                 <div className="search-container">
                   <input
                     type="search"
                     placeholder="Enter your postcode"
                     className="search-input"
                     onChange={handleInputChange}
                     ref={inputRef}
                     onFocus={() => setLocationPop(true)}
                   />
                   {locationPop && suggestions.length > 0 && (
                     <ul className="suggestions-dropdown">
                       {suggestions.map((suggestion) => (
                         <li
                           className="recent-location"
                           key={suggestion.place_id}
                           onClick={() => {
                             handleSuggestionClick(suggestion);
                             handlelocation(suggestion);
                             setSelectSuggestion(suggestion)
                           }}
                         >
                           <div className="recant-address">
                             <i className="ri-map-pin-line theme-color" />
                             <div>
                               <h5>{suggestion.description}</h5>
                               <h6>{suggestion.secondaryText}</h6>
                             </div>
                           </div>
                         </li>
                       ))}
                     </ul>
                   )}
                 </div>
               </form>
             </div>
             <Link href="" className="current-location">
               <div className="current-address">
                 <i className="ri-focus-3-line focus" />
                 <div onClick={handleLocationClick}>
                   <h5 className="mt-1">Use current location</h5>
                 </div>
               </div>
               <i className="ri-arrow-right-s-line arrow" />
             </Link>
             <h5 className="mt-sm-3 mt-2 fw-medium recent-title dark-text">
               Recent Location
             </h5>
             {address.map((address) => (
       <Link 
         href="#" 
         className="recent-location" 
         key={address.pkid} 
         onClick={() => saveAddressToLocalStorage(address)}
       >
         <div className="recant-address">
           <i className="ri-map-pin-line theme-color" />
           <div data-bs-dismiss="modal"
               aria-label="Close">
             <h5 >{address.title}</h5>
             <h6>{address.address}</h6>
           </div>
         </div>
       </Link>
     ))}
   {!allAddressesLoaded ? (
       <button onClick={showMoreAddresses} className="toggle-btn show-more-btn">
         Show More
       </button>
     ) : (
       <button onClick={showLessAddresses} className="toggle-btn show-more-btn">
         Show Less
       </button>
     )}
           </div>
           <div className="modal-footer">
             <Link href="#" className="btn gray-btn" data-bs-dismiss="modal">
               Close
             </Link>
             <Link
               className="btn theme-btn mt-0"
               data-bs-dismiss="modal"
               onClick={addressAdd}
             >
               Save
             </Link>
           </div>
         </div>
       </div>
     </div>
       
       } 
      <div
        className="modal address-details-modal fade"
        id="address-details"
        tabIndex={-1}
        aria-labelledby="addModalAdress"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-description" id="addModalAdress">
              So Help us to find you, to ensure {" "}
                <strong>accurate search results</strong>,{" "}
                <strong>delivery times</strong>, and <strong>fees</strong>,
                please tell us where  on{" "}
                <span className="" style={{color:"#009FE3"}}>
                  {selectSuggestion?.secondaryText}
                </span>
               <span> you are </span>
                .
              </p>

              <button
                type="button"
                className="another btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handlebtnclose}
                style={{marginTop:"-82px"}}

              />
            </div>
            <div className="modal-body">
              <form className="row g-3">
                <div className="col-12">
                  <label htmlFor="inputFirstname" className="form-label">
                    Enter building number or name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputFirstname"
                    placeholder="Enter building number or name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {/* <label htmlFor="inputFirstname" className="form-label">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputFirstname"
                    placeholder="Enter pincode number"
                    value={inputPincode}
                    onChange={(e) => setInputPincode(e.target.value)}
                  /> */}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn theme-btn mt-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={async (e) => {
                  await addressAdd(e);
                  // Close the modal by manipulating the DOM
                 
                  handlebtnclose();
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
