import React, { useEffect, useState } from "react";
import { customerdetail,logout } from "../utils/api";
import { Link } from "react-router-dom";
import { FaTrash } from 'react-icons/fa';
import { DeleteAccount } from "../utils/api";
import { FcFaq } from "react-icons/fc";
import { MdOutlineSupportAgent } from "react-icons/md";

import { MdOutlineLocalDining } from "react-icons/md";
const Profileshow = ({ selected }) => {

  const [user, setUser] = useState([]);
  const getData = async () => {
    const user = await customerdetail();
    if (user && user.customerdata && user.customerdata.length > 0) {

    setUser(user.customerdata[0]);

    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async()=>{
 

    const Logoutdata = await DeleteAccount()

    console.log(Logoutdata,"logggg")

    localStorage.removeItem('user');

    window.location.href = '/login';

  }

  const handleLogout = async()=>{
    const savedTab = localStorage.getItem('user');
    const data = {
      userid:user?.pkid
    }
    console.log(data,"dataaaaaa")

    const Logoutdata = await logout(data)

    console.log(Logoutdata,"logggg")

    localStorage.removeItem('user');

    window.location.href = '/login';

  }
  return (
    <>
      <div className="profile-sidebar sticky-top">
        <div className="profile-cover">
          {
            user.imagename ? 
            <img
                className="img-fluid profile-pic"
                src={`https://highwycombebites.com/documents/${user.imagename}`}
                alt="profile"
              /> 
              :
              <img
             className="img-fluid profile-pic"
             src={`assets/images/profile-picture.webp`}
             alt="profile"
           />
          }
        </div>
        <div className="profile-name">
          <h5 className="user-name">{user.name}</h5>
          <h6>{user.email}</h6>
        </div>
        <ul className="profile-list">
          <li className={selected === "setting" ? `active` : ""}>
            <i className="ri-user-3-line" />
            <Link to={"/setting"}>Profile</Link>
          </li>
          <li className={selected === "History" ? `active` : ""}>
            <i className="ri-shopping-bag-3-line" />
            <Link to={"/myhistory"}>Takeout History</Link>
          </li>
          <li
        className={selected === "DiningHistory" ? "active" : ""}
        // onClick={handleDelete}
      >
        <i className="icon"><MdOutlineLocalDining /></i> 
        <Link to={"/dininghistory"}>Dining History</Link>
      </li>
          <li className={selected === "Address" ? `active` : ""}>
            <i className="ri-map-pin-line" />
            <Link to={"/address"}>Address Book</Link>
          </li>
          <li className={selected === "Favourite" ? `active` : ""}>
            <i className="fa fa-heart" />
            <Link to={"/favourite"}>Favourite Restaurant</Link>
          </li>
          <li className={selected === "Help" ? `active` : ""}>
          <i
          className="bi bi-wallet2"
          style={{
            color: '#3D3D3D',
          }}
        ></i>            <Link to={"/loyaltypoint"}>Loyalty</Link>
          </li>
          <li className={selected === "FAQ" ? `active` : ""}>
          <FcFaq />
         <Link to={"/faq"}>FAQ</Link>
          </li>
          <li className={selected === "support" ? `active` : ""}>
          <MdOutlineSupportAgent />
          <Link to={"/support"}>Support</Link>
          </li>
          <li className={selected === "notify" ? `active` : ""}>
          <i className="fa fa-bell" />{" "}
          <Link to={"/notification"}>Notification</Link>
          </li>
          <li
          className="logout-button"
          onClick={handleLogout}
          aria-label="Log out"
          >
            <i className="ri-logout-box-r-line" />
            Log Out
          </li>
         
          <li
        className={selected === "Delete Account" ? "active" : ""}
        onClick={handleDelete}
        >
        <i className="icon"><FaTrash /></i> {/* Replace with new icon */}
        <Link to={"/login"}>Delete Account</Link>
      </li>
        </ul>
      </div>
    </>
  );
};

export default Profileshow;








{/* <div
className="modal address-details-modal fade"
id="address-details"
tabIndex={-1}
aria-labelledby="addModalAdress"
aria-hidden="true"
>
<div className="modal-dialog modal-dialog-centered">
  <div className="modal-content">
    <div className="modal-header">

<p className="modal-description" id="addModalAdress">
Help Us Find You To ensure <strong>accurate search results</strong>, <strong>delivery times</strong>, and <strong>fees</strong>, please tell us where you are on <span className="location-highlight">{selectedSuggestion?.secondaryText}</span>.
</p>

      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      />
    </div>
    <div className="modal-body">
      <form className="row g-3">
        <div className="col-12">
          <label htmlFor="inputFirstname" className="form-label">
          Enter building number or name
          </label>
          <input
            type="text"
            className="form-control"
            id="inputFirstname"
            placeholder="Enter building number or name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

      
    
      </form>
    </div>
    <div className="modal-footer">
     
      <button 
      type='submit'
      className="btn theme-btn mt-0"
      onClick={addressAdd}
      >
        SUBMIT
      </button>
    </div>
  </div>
</div>
</div> */}



// const [activeTab, setActiveTab] = useState(() => {
//   const savedTab = localStorage.getItem('activeTab');
//   return savedTab ? savedTab : 'Collection';
// });
// const [selectedSuggestion, setSelectedSuggestion] = useState("");

// // Handle tab click
// const handleTabClick = (tab) => {
//   setActiveTab(tab);
//   localStorage.setItem('activeTab', tab);
// };

// const [show, setShow] = useState(false);

// const handleClose = () => setShow(false);
// const handleShow = () => setShow(true);

// console.log(show,"")

// #location
// const [suggestions, setSuggestions] = useState([]);
// const [visible, setVisible] = useState(false);
// const inputRef = useRef(null);
// const [location,setLocation] = useState(null)



// const fetchSuggestions = async (query) => {
//   if (query.length > 0) {
//     try {
//       const response = await axios.get(
//         `https://www.highwycombebites.com/api/customer/geolocation?query=${query}&type=regions`
//       );
      
//       const data = response.data;
//       if (data.predictions) {
//         const formattedSuggestions = data.predictions.map((prediction) => ({
//           place_id: prediction.placeId,
//           description: `${prediction.structuredFormatting.mainText}`,
//           secondaryText: `${prediction.structuredFormatting.mainText}, ${prediction.structuredFormatting.secondaryText}`,
//         }));
//         setSuggestions(formattedSuggestions);
//         setVisible(true);
//       }
//     } catch (error) {
//       console.error("Error fetching location suggestions:", error);
//     }
//   } else {
//     setSuggestions([]);
//     setVisible(false);
//   }
// };

// const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, 300), []);

// const handleInputChange = (event) => {
//   const query = event.target.value;
//   console.log(query,"input")
//   debouncedFetchSuggestions(query);
// };

// const handleSuggestionClick = (suggestion) => {
//   // inputRef.current.value = suggestion.description;
//   console.log(suggestion.secondaryText,"suggestion")
//   inputRef.current.value = suggestion.secondaryText;
//   setSuggestions([]);
//   setVisible(false);
// };

// const handlelocation = async (suggestion) => {
//   console.log(suggestion.secondaryText, "suggestion");
//   const response = await axios.get(`https://highwycombebites.com/api/customer/geocode?place=${suggestion.secondaryText}`);
//   if (response?.data?.results[0]?.geometry?.location) {
//     const locationData = response.data.results[0].geometry.location;
//     setLocation(locationData);
//     console.log(location, "lat,lng");
//   }
// };

// useEffect(() => {
//   if (location) {
//     console.log("Location has been updated:", location);
//   }
// }, [location]);

// // address
// const [address, setAddress] = useState([]);
// const Navigate = useNavigate();
// const [addres, setAddres] = useState(''); // Added address state
// const [title, setTitle] = useState(''); // Added title state
// const addressAdd = async(e)=>{
//   e.preventDefault();
//   const storedUser = JSON.parse(localStorage.getItem("user"));

//   const data = {
//     userid:storedUser?.userid,
//     title:title,
//     address:addres,
//     lat:location?.lat,
//     lng:location?.lng,     
//   }
//   const add = await addcustinfo(data)
//       setTitle('');
//       setAddres('');
//      if(add.status === "1"){
//        Navigate("/address")
//      }
// }