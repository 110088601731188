import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import { insertSupportedBybites } from "../../utils/api";
import Donate from "../../assets/donate.jpg";

const SupportedForm = () => {
  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    bussinessname: "",
    bussinessaddress: "",
    bussinesstype: "",
    city: "",
    postcode: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.firstname.trim()) newErrors.firstname = "First Name is required.";
    if (!formData.lastname.trim()) newErrors.lastname = "Last Name is required.";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required.";
    if (!formData.email.trim()) newErrors.email = "Email is required.";
    if (!formData.bussinessname.trim()) newErrors.bussinessname = "Business Name is required.";
    if (!formData.bussinessaddress.trim()) newErrors.bussinessaddress = "Business Address is required.";
    if (!formData.bussinesstype.trim()) newErrors.bussinesstype = "Business Type is required.";
    if (!formData.city.trim()) newErrors.city = "City is required.";
    if (!formData.postcode.trim()) newErrors.postcode = "Postcode is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      Swal.fire({
        icon: "error",
        title: "Form Submission Failed",
        text: "Please correct the errors and try again.",
        confirmButtonText: "OK",
        confirmButtonColor: "#009fe3",
      });
      return;
    }

    try {
      const res = await insertSupportedBybites(formData);
      console.log("Response", res);
      Swal.fire({
        icon: "success",
        title: "Application Submitted",
        text: "Your application has been submitted successfully!",
        confirmButtonText: "OK",
        confirmButtonColor: "#009fe3",
      });

      setFormData({
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        bussinessname: "",
        bussinessaddress: "",
        bussinesstype: "",
        city: "",
        postcode: "",
      });
    } catch (error) {
      console.error("Unable to Process.", error);
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "An error occurred. Please try again later.",
        confirmButtonText: "OK",
        confirmButtonColor: "#009fe3",
      });
    }
  };

  return (
    <div className="contain" id="contain" style={{ paddingBottom: "0px" }}>
      <div className="py-3">
        <div className="row">
          <div
            className="col-12 col-md-6 logo-section d-flex flex-column align-items-start justify-content-center mb-3 mb-md-0 d-none d-md-flex"
            style={{ backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "8px" }}
          >
            <h2
              className="text-dark text-center text-md-start mb-4"
              style={{ fontWeight: "600", fontSize: "1.8rem", lineHeight: "1.5" }}
            >
              Enter Your Business Details & Sign Up Today.
            </h2>
            <img
              src={`${Donate}`}
              alt="Business Logo"
              className="img-fluid mt-3"
              style={{
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                width: "100%",
                height: "60vh",
                objectFit: "cover",
              }}
            />
          </div>

          <div className="col-12 col-md-6">
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="d-flex flex-column align-items-center"
            >
              {[
                { label: "First Name", name: "firstname", type: "text", placeholder: "First Name" },
                { label: "Last Name", name: "lastname", type: "text", placeholder: "Last Name" },
                { label: "Phone", name: "phone", type: "text", placeholder: "Phone Number" },
                { label: "Email", name: "email", type: "email", placeholder: "Email" },
                { label: "Organisation Name", name: "bussinessname", type: "text", placeholder: "Organisation Name" },
                { label: "Address", name: "bussinessaddress", type: "text", placeholder: "Address" },
                { label: "City", name: "city", type: "text", placeholder: "City" },
                { label: "Postcode", name: "postcode", type: "text", placeholder: "Postcode" },
              ].map(({ label, name, type, placeholder }) => (
                <div className="mb-3 w-100" key={name}>
                  <label className="form-label text-light">{label} *</label>
                  <input
                    type={type}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className={`form-control ${errors[name] ? "is-invalid" : ""}`}
                    placeholder={placeholder}
                  />
                  {errors[name] && <div className="invalid-feedback">{errors[name]}</div>}
                </div>
              ))}

              <div className="mb-3 w-100">
                <label className="form-label text-light">Type *</label>
                <select
                  name="bussinesstype"
                  value={formData.bussinesstype}
                  onChange={handleChange}
                  className={`form-control ${errors.bussinesstype ? "is-invalid" : ""}`}
                >
                  <option value="">Select Business Type</option>
                  <option value="Restaurant">Restaurant</option>
                  <option value="Grocery">Grocery</option>
                </select>
                {errors.bussinesstype && <div className="invalid-feedback">{errors.bussinesstype}</div>}
              </div>

              <button
                type="submit"
                className="btn mt-3 w-100"
                style={{ backgroundColor: "#019ee3", color: "white" }}
              >
                Apply
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportedForm;








// {[{
//   label: 'First Name', name: 'firstName', type: 'text', placeholder: 'First Name'
// }, {
//   label: 'Last Name', name: 'lastName', type: 'text', placeholder: 'Last Name'
// }, {
//   label: 'Phone', name: 'phone', type: 'text', placeholder: 'Enter UK Phone Number'
// }, {
//   label: 'Email', name: 'email', type: 'email', placeholder: 'Email'
// }, {
//   label: 'Organisation Name', name: 'organisationName', type: 'text', placeholder: 'Organisation Name'
// }, {
//   label: 'Address', name: 'address', type: 'text', placeholder: 'Address'
// }, {
//   label: 'City', name: 'city', type: 'text', placeholder: 'City'
// }, {
//   label: 'Postcode', name: 'postcode', type: 'text', placeholder: 'Postcode'
// }].map(({ label, name, type, placeholder }) => (
//   <div className="mb-3 w-100" key={name}>
//     <label className="form-label text-light">{label} *</label>
//     <input
//       type={type}
//       name={name}
//       value={formData[name]}
//       onChange={handleChange}
//       className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
//       placeholder={placeholder}
//     />
//     {errors[name] && <div className="invalid-feedback">{errors[name]}</div>}
//   </div>
// ))}