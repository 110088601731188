import React, { useEffect, useState } from "react";
import { addToCart, chooseAdd } from "../utils/api";
import {  toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { getcart,incrementCount } from "../store/feature/cartSlice";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AddonPopup = ({ onClose, food, restId }) => {
  console.log(food,"addon")
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const {count } = useSelector((store) => store.Cart);
  const activeTab= useSelector((store) => store.User.activeTab);
  const dispatch = useDispatch();
 
  // const storedAddress = JSON.parse(localStorage.getItem("userAddress"));
  const navigate = useNavigate()
  const [addon, setAddon] = useState([]);
  const [cost, setCost] = useState(food?.cost);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [totalCost, setTotalCost] = useState(0);

  

  console.log(count,"countsss")
 
  // useEffect(() => {
  //   // Only dispatch getcart if cartCount is not null
  //   if (count) {
  //     console.log(count, "count22")
  //     dispatch(getcart());

  //   }
  // }, [count, dispatch]);



  useEffect(() => {
    const callAPI = async () => {
      const data = {
        catid: food?.pkid,
        isdelivery: "1",
      };
      const res = await chooseAdd(data);
      // console.log("res", res);
      setAddon(res?.menutypes);
    };

    callAPI();
  }, [food?.pkid]);

  useEffect(() => {
    if (addon.length > 0) {
      setSelectedSize(addon[0].type);
      setSelectedItem(addon[0]);

      const initialOptions = {};
      addon[0].menutypecategorys.forEach((category) => {
         if (category.menutypesubcategorys.length > 0) {
          initialOptions[category.menutypecategoryid] = category.menutypesubcategorys
          .filter(subcategory => subcategory.isselected === "1")
          .map(subcategory => subcategory.menutypesubcategoryid);
      }
      });
      setSelectedOptions(initialOptions);
    }
  }, [addon]);

  useEffect(() => {
    // Calculate total cost based on selected options
    // let total = parseFloat(cost);
    let total = 0;

    addon.forEach((menu) => {
      if (menu.type === selectedSize) {
        total += parseFloat(menu.cost); // Add the cost of the selected size
        menu.menutypecategorys.forEach((category) => {
          category.menutypesubcategorys.forEach((subcategory) => {
            const subcatId = subcategory.menutypesubcategoryid;
            if (
              selectedOptions[category.menutypecategoryid]?.includes(subcatId)
            ) {
              total += parseFloat(subcategory.cost);
            }
          });
        });
      }
    });

    setTotalCost(total);
  }, [selectedSize, selectedOptions, addon]);

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    const selectedMenu = addon.find((menu) => menu.type === size);
    setSelectedItem(selectedMenu);
    const newOptions = {};
    selectedMenu.menutypecategorys.forEach((category) => {
      if (category.menutypesubcategorys.length > 0) {
        newOptions[category.menutypecategoryid] = [];
      }
    });
    setSelectedOptions(newOptions);
  };

  const handleOptionChange = (categoryId, subCategoryId) => {
    setSelectedOptions((prevOptions) => {
      const currentSelections = prevOptions[categoryId];
      let updatedSelections = [];

      // Find the current category configuration
      const category = addon
        .find((menu) => menu.type === selectedSize)
        .menutypecategorys.find((cat) => cat.menutypecategoryid === categoryId);

      if (!category) {
        return prevOptions;
      }

      // Check if multiple selections are allowed
      const isMultipleAllowed = category.ismultiple === "1";
      const maxSelections = parseInt(category.multiple, 10) || 0;

      if (!isMultipleAllowed) {
        if (currentSelections.includes(subCategoryId)) {
          updatedSelections = []; // Deselect the option if already selected
        } else {
          updatedSelections = [subCategoryId]; // Select only the new option
        }
      } else if (!currentSelections.includes(subCategoryId)) {
        if (maxSelections === 0 || currentSelections.length < maxSelections) {
          updatedSelections = [...currentSelections, subCategoryId];
        } else {
          updatedSelections = currentSelections;
        }
      } else {
        updatedSelections = currentSelections.filter(
          (id) => id !== subCategoryId
        );
      }

      return {
        ...prevOptions,
        [categoryId]: updatedSelections,
      };
    });
  };

  const currentMenu = addon.find((menu) => menu.type === selectedSize);

  const isProceedDisabled = () => {
    // Check if all required categories have selections
    const requiredCategories = currentMenu?.menutypecategorys.filter(
      (category) => category.isrequired === "1"
    );

    if (!requiredCategories || requiredCategories.length === 0) {
      return false; // No required categories
    }

    for (let category of requiredCategories) {
      const categoryOptions = selectedOptions[category.menutypecategoryid];
      if (!categoryOptions || categoryOptions.length === 0) {
        return true; // Required category has no selection
      }
    }

    return false; // All required categories have selections
  };

  const handleProceed = async() => {

    if(!storedUser){
      navigate("/login")
    }
    const allSelectedValues = Object.values(selectedOptions).flat();

    const sendData = {
      userid: storedUser?.userid,
      // userid: "91",
      restId:restId,
      type: activeTab == "Delivery" ? "delivery" : "takeaway",
      catid: food.catid,
      foodid: food.pkid,
      typeid: selectedItem?.menutypeid || '',
      extratopupid: allSelectedValues,
      quantity: "1",
      cartid: "",
      cartdetailid: "",
    };
    console.log("storedUser", storedUser);
    
    const data = await addToCart(sendData);
    console.log('cartCount',data);
        if(data.status == "1"){
    }
    onClose()

  };

  const handleIncrement = () => {
    dispatch(incrementCount());
};
  const handleClick = () => {
    console.log("function")
    handleProceed()
setTimeout(()=>{
  handleIncrement()
},2000)
setTimeout(()=>{
  handleIncrement()
},200)
};

  return (
    <div
      className="modal customized-modal show d-block bleck-bg-popup"
      id="customized"
      tabIndex={-1}
    >
      {/* <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="container">
            <div className="filter-header">
              <h5 className="title">Custom Food Total £</h5>
              <div className="price-items">
                <h3>{totalCost.toFixed(2)}</h3>
              </div>
              <p
                onClick={onClose}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <h5>Select Your Order</h5>
            <div className="btn-group" role="group" aria-label="Pizza sizes" style={{backgroundColor:"#009fe3"}}>
              {addon.map((menu) => (
                <button
                  key={menu.menutypeid}
                  type="button"
                  className={`btn ${
                    selectedSize === menu.type ? "theme-btn" : "btn-light"
                  }`}
                  onClick={() => handleSizeChange(menu.type)}
                >
                  {menu.type} <br /> £{menu.cost}
                </button>
              ))}
            </div>
            <div className="filter-body">
              {currentMenu?.menutypecategorys?.map((category) => (
                <>
            <div className="filter-title">
  <h5 className="fw-medium dark-text d-flex justify-content-between">
    <span>{category.name} </span>
    {category.isrequired === "1" && (
      <span style={{fontSize:"12px",color:"#dc3545a8"}}>Required</span>
    )}
  </h5>
</div>

<ul className="filter-list" style={{ listStyleType: 'none', padding: 0 }}>
  {category?.menutypesubcategorys?.map((subcategory) => (
    <li
      key={subcategory.menutypesubcategoryid}
      style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }} 
    >
      <input
        className="form-check-input"
        type="checkbox"
        style={{
          marginRight: '10px', 
          border: '1px solid black',
          marginTop:"-2px"
        }}
        id={`subcategory-${subcategory.menutypesubcategoryid}`}
        value={subcategory.menutypesubcategoryid}
        checked={selectedOptions[category.menutypecategoryid]?.includes(
          subcategory.menutypesubcategoryid
        )}
        onChange={() =>
          handleOptionChange(
            category.menutypecategoryid,
            subcategory.menutypesubcategoryid
          )
        }
      />
      <label
        className="form-check-label"
        htmlFor={`subcategory-${subcategory.menutypesubcategoryid}`}
        style={{
          display: 'flex',
          justifyContent: 'space-between', 
          width: '100%', 
        }}
      >
        <span style={{ flex: 1 }}>{subcategory.name}</span>
        {subcategory.cost > 0 && (
          <span style={{ marginLeft: 'auto', color: '#333' }}>£{subcategory.cost}</span> 
        )}
      </label>
    </li>
  ))}
</ul>



                 
                </>
              ))}
            </div>
            <div className="price-items">
              <h3>Total £{totalCost.toFixed(2)}</h3>
            </div>
            <div className="filter-footer">
              <button
                className="btn theme-btn add-btn w-100 mt-0"
                onClick={handleClick}
                disabled={isProceedDisabled()}
                style={{ backgroundColor: isProceedDisabled() ? "grey" : "" }}
              >
                +Add
              </button>
            </div>
          </div>
        </div>
      </div> */}
<div
  className="modal-dialog modal-dialog-centered"
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
    margin: "0 10px", // Add margin for mobile
  }}
>
  <div
    className="modal-content"
    style={{
      borderRadius: "10px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      width: "100%", // Full width for mobile
      maxWidth: "400px", // Limit modal width
    }}
  >
    <div
      className="container"
      style={{
        padding: "20px",
        maxHeight: "80vh", // Ensure modal fits the screen
        overflowY: "auto", // Allow scrolling
      }}
    >
      <div
        className="filter-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <h5
          className="title"
          style={{ fontWeight: "600", fontSize: "18px", color: "#333" }}
        >
          Custom Food Total £
        </h5>
        <div
          className="price-items"
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            marginLeft: "auto",
          }}
        >
          <h3>{totalCost.toFixed(2)}</h3>
        </div>
        <p
          onClick={onClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          style={{
            cursor: "pointer",
            fontSize: "16px",
            color: "#999",
            backgroundColor: "transparent",
            border: "none",
            marginLeft: "10px",
          }}
        />
      </div>
      <h5 style={{ fontWeight: "600", marginBottom: "15px" }}>Select Your Order</h5>
      <div
        className="btn-group"
        role="group"
        aria-label="Pizza sizes"
        style={{
          display: "flex",
          flexWrap: "wrap", // Allow wrapping for mobile
          gap: "10px",
          justifyContent: "center",
        }}
      >
{addon.map((menu) => (
  <button
    key={menu.menutypeid}
    type="button"
    className={`btn ${
      selectedSize === menu.type ? "theme-btn" : "btn-light"
    }`}
    onClick={() => handleSizeChange(menu.type)}
    style={{
      padding: "10px 15px",
      fontSize: "14px",
      fontWeight: "500",
      border: "1px solid #ddd",
      borderRadius: "5px",
      backgroundColor: selectedSize === menu.type ? "#007bff" : "#f8f9fa",
      color: selectedSize === menu.type ? "#fff" : "#000",
      flex: "1 1 45%", // Ensure buttons resize for mobile
      maxWidth: "45%",
      textAlign: "center", // Align text centrally
      wordWrap: "break-word", // Allow wrapping of long words
      overflow: "hidden", // Prevent text overflow
      whiteSpace: "normal", // Allow text to wrap to the next line
    }}
  >
    {menu.type} <br /> £{menu.cost}
  </button>
))}

      </div>
      <div className="filter-body" style={{ marginTop: "20px" }}>
        {currentMenu?.menutypecategorys?.map((category) => (
          <div key={category.menutypecategoryid} style={{ marginBottom: "20px" }}>
            <div
              className="filter-title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              <span>{category.name}</span>
              {category.isrequired === "1" && (
                <span style={{ fontSize: "12px", color: "#dc3545a8" }}>Required</span>
              )}
            </div>
            <ul
              className="filter-list"
              style={{ listStyleType: "none", padding: 0, marginTop: "10px" }}
            >
              {category?.menutypesubcategorys?.map((subcategory) => (
                <li
                  key={subcategory.menutypesubcategoryid}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`subcategory-${subcategory.menutypesubcategoryid}`}
                    value={subcategory.menutypesubcategoryid}
                    checked={selectedOptions[category.menutypecategoryid]?.includes(
                      subcategory.menutypesubcategoryid
                    )}
                    onChange={() =>
                      handleOptionChange(
                        category.menutypecategoryid,
                        subcategory.menutypesubcategoryid
                      )
                    }
                    style={{
                      marginRight: "10px",
                      border: "1px solid black",
                      marginTop: "-2px",
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`subcategory-${subcategory.menutypesubcategoryid}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span style={{ flex: 1 }}>{subcategory.name}</span>
                    {subcategory.cost > 0 && (
                      <span style={{ marginLeft: "auto", color: "#333" }}>
                        £{subcategory.cost}
                      </span>
                    )}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div
        className="filter-footer"
        style={{
          marginTop: "20px",
        }}
      >
        <button
          className="btn theme-btn add-btn w-100 mt-0"
          onClick={handleClick}
          disabled={isProceedDisabled()}
          style={{
            backgroundColor: isProceedDisabled() ? "grey" : "#007bff",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "500",
            padding: "10px",
            borderRadius: "5px",
            cursor: isProceedDisabled() ? "not-allowed" : "pointer",
          }}
        >
          +Add
        </button>
      </div>
    </div>
  </div>
</div>


    </div>
  );
};

export default AddonPopup;



