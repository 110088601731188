import React, { useState } from "react";
import "./BitesForm.css";
import Swal from "sweetalert2";
import { insertbitesforbussiness } from "../utils/api";
import Donate from "../assets/buffer.jpg";

const BitesForm = () => {
  const [formData, setFormData] = useState({
    Name: "",
    Phone: "",
    Email: "",
    Organisation: "",
    Address: "",
    City: "",
    Postcode: "",
    Nominate: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "Phone") {
      // Restrict phone input to valid characters
      if (!/^(\+|\d)*$/.test(value)) return;

      // Restrict UK phone number length
      if (value.startsWith("+44")) {
        if (value.length > 13) return; // +44 + 10 digits
      } else if (value.startsWith("07")) {
        if (value.length > 11) return; // 07 + 9 digits
      }
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = () => {
    let newErrors = {};

   // Check for required fields
   Object.entries(formData).forEach(([key, value]) => {
    if (!value.trim() && key !== "Nominate") {
      newErrors[key] = `${key} is required.`;
    }
  });

    // Additional validation
    if (
      formData.Email &&
      !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.Email)
    ) {
      newErrors.Email = "Invalid email address.";
    }

    if (formData.Phone) {
      // Remove spaces and non-numeric characters (except for the + sign)
      const cleanedPhone = formData.Phone.replace(/\s+/g, '').replace(/[^\d+]/g, ''); // Keeps the + sign, removes other non-numeric chars
  
      console.log('Cleaned Phone:', cleanedPhone);  // Log the cleaned phone for debugging
  
      // Updated regex for validating UK phone numbers (including +44 or 07 with 9 digits)
      if (!/^(\+44\d{10}|07\d{9})$/.test(cleanedPhone)) {
        newErrors.Phone = "Enter a valid UK phone number.";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      Swal.fire({
        title: "Validation Error",
        text: "Please correct the errors in the form.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#009fe3",
      });
      return;
    }

    try {
      const response = await insertbitesforbussiness(formData);

      if (response.success === "0") {
        Swal.fire({
          title: response.returnmsg || "Duplicate Email",
          text: "The email you entered already exists.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#009fe3",
        });
      } else {
        Swal.fire({
          title: "Success!",
          text: "Form submitted successfully!",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#009fe3",
        });

        // Reset form data
        setFormData({
          Name: "",
          Phone: "",
          Email: "",
          Organisation: "",
          Address: "",
          City: "",
          Postcode: "",
          Nominate: "",
        });

        setErrors({});
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to submit the form. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#009fe3",
      });
    }
  };

  return (
    <div className="contain" id="contain">
      <div className="py-3">
        <div className="row">
          <div
            className="col-12 col-md-6 logo-section d-flex flex-column align-items-start justify-content-center mb-3 mb-md-0 d-none d-md-flex"
            style={{ backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "8px" }}
          >
            <h2
              className="text-dark text-center text-md-start mb-4"
              style={{ fontWeight: "600", fontSize: "1.8rem", lineHeight: "1.5" }}
            >
              Enter Your Business Details & Sign Up Today.
            </h2>

            <img
              src={`${Donate}`}
              alt="Business Logo"
              className="img-fluid mt-3"
              style={{
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                width: "100%",
                height: "60vh",
                objectFit: "cover",
              }}
            />
          </div>

          <div className="col-12 col-md-6">
            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
              {[
                { label: "Full Name", name: "Name", type: "text", placeholder: "Full Name" },
                { label: "Phone", name: "Phone", type: "text", placeholder: "Phone" },
                { label: "Email", name: "Email", type: "email", placeholder: "Email" },
                { label: "Organisation", name: "Organisation", type: "text", placeholder: "Organisation Name" },
                { label: "Address", name: "Address", type: "text", placeholder: "Address" },
                { label: "City", name: "City", type: "text", placeholder: "City" },
                { label: "Post Code", name: "Postcode", type: "text", placeholder: "Post Code" },
              ].map(({ label, name, type, placeholder }) => (
                <div className="mb-3 w-100" key={name}>
                  <label className="form-label text-light">{label} *</label>
                  <input
                    type={type}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className={`form-control ${errors[name] ? "is-invalid" : ""}`}
                    placeholder={placeholder}
                  />
                  {errors[name] && <div className="invalid-feedback">{errors[name]}</div>}
                </div>
              ))}
<div className="mb-3 w-100">
  <label className="form-label text-light">Message</label>
  <textarea
    name="Nominate"
    value={formData.Nominate}
    onChange={handleChange}
    className="form-control"
    rows="3"
    placeholder="Add message"
  ></textarea>
</div>

              <button
                type="submit"
                className="btn hover-effect mt-3"
                style={{ backgroundColor: "#019ee3", color: "white" }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BitesForm;
